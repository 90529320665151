<template>
  <section class="app-main">
    <!-- 帐号禁用时的提示信息 -->
    <div
      class="forbidden-tip"
      v-if="
        ($store.state.num.activeAdAccount.accountStatus &&
          $store.state.num.activeAdAccount.accountStatus != 1 &&
          key != '/ad/multiAccountManageIndex') ||
        ($store.state.num.activeAdAccount.spendCap &&
          $store.state.num.activeAdAccount.amountSpent &&
          $store.state.num.activeAdAccount.spendCap - $store.state.num.activeAdAccount.amountSpent < 5)
      "
    >
      <p
        class="dp-flex align-item-c"
        v-if="$store.state.num.activeAdAccount.accountStatus && $store.state.num.activeAdAccount.accountStatus != 1"
      >
        <span class="small-dot"></span> 广告账号状态是：<strong>禁用</strong>
        ，可在左侧广告资产或头部账号切换中同步账号后更新账号（<span class="small-font"
          >只有启用中的账号才能创建/编辑广告</span
        >）！
      </p>
      <p
        class="dp-flex align-item-c"
        v-if="
          $store.state.num.activeAdAccount.spendCap &&
          $store.state.num.activeAdAccount.amountSpent &&
          $store.state.num.activeAdAccount.spendCap - $store.state.num.activeAdAccount.amountSpent < 5
        "
      >
        <span class="small-dot"></span>账号额度小于5，请前往左侧广告资产查看。
      </p>
    </div>
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <router-view :key="key + '_' + id + syncN" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  data() {
    return {
      id: 0,
      syncN: 0,
    };
  },
  watch: {
    // 监听广告账户id
    '$store.getters.accountId': function (v, ov) {
      // console.log("$store.getters.accountId-change", v, ov);
      if (v !== ov) {
        this.id++;
      }
    },
    // 监听同步
    '$store.state.num.isSync': {
      handler(val) {
        if (val) {
          // console.log("$store.state.num.isSync-true", val);
          this.syncN++;
        }
      },
    },
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 50px);

  width: 100%;
  position: relative;
  background: #f5f5f5;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
.forbidden-tip {
  position: relative;
  top: -16px;
  width: 100%;
  /* height: 40px; */
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  padding: 8px 15px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.small-font {
  font-size: 12px;
  color: #999;
}
.small-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #999;
  margin-right: 5px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
