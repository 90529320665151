var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"app-main"},[(
      (_vm.$store.state.num.activeAdAccount.accountStatus &&
        _vm.$store.state.num.activeAdAccount.accountStatus != 1 &&
        _vm.key != '/ad/multiAccountManageIndex') ||
      (_vm.$store.state.num.activeAdAccount.spendCap &&
        _vm.$store.state.num.activeAdAccount.amountSpent &&
        _vm.$store.state.num.activeAdAccount.spendCap - _vm.$store.state.num.activeAdAccount.amountSpent < 5)
    )?_c('div',{staticClass:"forbidden-tip"},[(_vm.$store.state.num.activeAdAccount.accountStatus && _vm.$store.state.num.activeAdAccount.accountStatus != 1)?_c('p',{staticClass:"dp-flex align-item-c"},[_c('span',{staticClass:"small-dot"}),_vm._v(" 广告账号状态是："),_c('strong',[_vm._v("禁用")]),_vm._v(" ，可在左侧广告资产或头部账号切换中同步账号后更新账号（"),_c('span',{staticClass:"small-font"},[_vm._v("只有启用中的账号才能创建/编辑广告")]),_vm._v("）！ ")]):_vm._e(),(
        _vm.$store.state.num.activeAdAccount.spendCap &&
        _vm.$store.state.num.activeAdAccount.amountSpent &&
        _vm.$store.state.num.activeAdAccount.spendCap - _vm.$store.state.num.activeAdAccount.amountSpent < 5
      )?_c('p',{staticClass:"dp-flex align-item-c"},[_c('span',{staticClass:"small-dot"}),_vm._v("账号额度小于5，请前往左侧广告资产查看。 ")]):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"fade-transform","mode":"out-in"}},[_c('router-view',{key:_vm.key + '_' + _vm.id + _vm.syncN})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }